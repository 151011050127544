export default [
  {
    path: '/roles/clientes',
    meta: { title: 'Roles y Permisos de Clientes' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'GroupsCustomer',
        meta: { title: 'Clientes', section: 'role' },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/customers/Index'
          )
      },
      {
        path: 'create',
        name: 'GroupCustomerCreate',
        meta: {
          title: 'Nuevo Rol de Cliente'
        },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/customers/Edit'
          )
      },
      {
        path: ':id',
        name: 'GroupCustomerEdit',
        meta: {
          title: 'Editar Rol de Cliente'
        },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/customers/Edit'
          )
      }
    ]
  },

  {
    path: '/roles/administradores',
    meta: { title: 'Roles y Permisos de Administradores' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'GroupsAdmin',
        meta: { title: 'Administradores', section: 'role' },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/admin/Index'
          )
      },
      {
        path: 'create',
        name: 'GroupAdminCreate',
        meta: {
          title: 'Nuevo Rol de Administrador'
        },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/admin/Edit'
          )
      },
      {
        path: ':id',
        name: 'GroupAdminEdit',
        meta: {
          title: 'Editar Rol de Administrador'
        },
        component: () =>
          import(
            /* webpackChunkName: "groups" */
            '@/views/general/groups/admin/Edit'
          )
      }
    ]
  },

  {
    path: '/usuarios',
    meta: { title: 'Usuarios' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Users',
        meta: { title: 'Usuarios', section: 'user', permission: 'menu-users' },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/users/Index'
          )
      },
      {
        path: 'create',
        name: 'UserCreate',
        meta: {
          title: 'Creación de Usuarios'
        },
        component: () =>
          import(/* webpackChunkName: "general" */ '@/views/general/users/Edit')
      },
      {
        path: ':id',
        name: 'UserEdit',
        meta: {
          title: 'Editar Usuario'
        },
        component: () =>
          import(/* webpackChunkName: "general" */ '@/views/general/users/Edit')
      }
    ]
  },

  {
    path: '/preguntas',
    meta: { title: 'Preguntas de Seguridad' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Questions',
        meta: { title: 'Preguntas de Seguridad', section: 'question' },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/questions/Index'
          )
      },
      {
        path: 'create',
        name: 'QuestionCreate',
        meta: {
          title: 'Nueva Pregunta de Seguridad'
        },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/questions/Edit'
          )
      },
      {
        path: ':id',
        name: 'QuestionEdit',
        meta: {
          title: 'Editar Pregunta de Seguridad'
        },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/questions/Edit'
          )
      }
    ]
  },

  {
    path: '/brokers',
    meta: { title: 'Brokers' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Brokers',
        meta: { title: 'Brokers', section: 'broker' },
        component: () =>
          import(
            /* webpackChunkName: "Brokers" */
            '@/views/brokers/Index'
          )
      },
      {
        path: 'create',
        name: 'BrokerCreate',
        meta: {
          title: 'Nuevo Broker'
        },
        component: () =>
          import(
            /* webpackChunkName: "Brokers" */
            '@/views/brokers/Edit'
          )
      },
      {
        path: ':id',
        name: 'BrokerEdit',
        meta: {
          title: 'Editar Broker'
        },
        component: () =>
          import(
            /* webpackChunkName: "Brokers" */
            '@/views/brokers/Edit'
          )
      }
    ]
  },

  {
    path: '/estado-cuenta',
    meta: { title: 'Estado de Cuenta' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'AccountStatement',
        meta: { title: 'Estado de Cuenta', section: 'account-statement' },
        component: () =>
          import(
            /* webpackChunkName: "AccountStatement" */
            '@/views/accountStatement/Index'
          )
      },
      {
        path: 'generar',
        name: 'AccountStatementGenerate',
        meta: {
          title: 'Generar Estado de Cuenta'
        },
        component: () =>
          import(
            /* webpackChunkName: "AccountStatement" */
            '@/views/accountStatement/Generate'
          )
      },
      {
        path: 'publicar',
        name: 'AccountStatementPublish',
        meta: {
          title: 'Publicar Estado de Cuenta'
        },
        component: () =>
          import(
            /* webpackChunkName: "AccountStatement" */
            '@/views/accountStatement/Publish'
          )
      },
      {
        path: 'sustituir',
        name: 'AccountStatementReplace',
        meta: {
          title: 'Sustituir Estado de Cuenta'
        },
        component: () =>
          import(
            /* webpackChunkName: "AccountStatement" */
            '@/views/accountStatement/Replace'
          )
      },
    ]
  },

  {
    path: '/parametros',
    meta: { title: 'Parametros' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Settings',
        meta: { title: 'Parámetros', section: 'setting' },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/ajustes/Index'
          )
      },
      {
        path: 'create',
        name: 'SettingCreate',
        meta: {
          title: 'Nuevo Parámetro'
        },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/ajustes/Edit'
          )
      },
      {
        path: ':id',
        name: 'SettingEdit',
        meta: {
          title: 'Editar Parámetro'
        },
        component: () =>
          import(
            /* webpackChunkName: "general" */
            '@/views/general/ajustes/Edit'
          )
      }
    ]
  },

  {
    path: '/multi-idiomas',
    meta: { title: 'Multi-idiomas' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Languages',
        meta: {
          title: 'Idiomas',
          section: 'language',
          permission: 'menu-multi-languages'
        },
        component: () =>
          import(
            /* webpackChunkName: "languages" */
            '@/views/general/languages/languages/Index'
          )
      },
      {
        path: 'create',
        name: 'LanguageCreate',
        meta: {
          title: 'Nuevo Idioma'
        },
        component: () =>
          import(
            /* webpackChunkName: "languages" */
            '@/views/general/languages/languages/Edit'
          )
      },
      {
        path: ':id',
        name: 'LanguageEdit',
        meta: {
          title: 'Editar Idioma'
        },
        component: () =>
          import(
            /* webpackChunkName: "languages" */
            '@/views/general/languages/languages/Edit'
          )
      }
    ]
  },

  {
    path: '/multi-idiomas/:id/traducciones',
    meta: { title: 'Multi-idiomas' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Translations',
        meta: { title: 'Traducciones', section: 'translation' },
        component: () =>
          import(
            /* webpackChunkName: "translations" */
            '@/views/general/languages/translations/Index'
          )
      },
      {
        path: 'create',
        name: 'TranslationCreate',
        meta: {
          title: 'Nueva Traducción'
        },
        component: () =>
          import(
            /* webpackChunkName: "translations" */
            '@/views/general/languages/translations/Edit'
          )
      },
      {
        path: ':id',
        name: 'TranslationEdit',
        meta: {
          title: 'Editar Traducción'
        },
        component: () =>
          import(
            /* webpackChunkName: "translations" */
            '@/views/general/languages/translations/Edit'
          )
      }
    ]
  }
]
