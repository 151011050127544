<template>
  <v-app id="logoscorp" class="logoscorp">
    <!-- DRAWER -->
    <AppDrawer />
    <!-- TOOLBAR -->
    <AppToolBar />

    <!-- ROUTER VIEW -->
    <v-fade-transition mode="out-in">
      <router-view class="container content-box mt-8" />
    </v-fade-transition>

    <!-- FOOTER -->
    <AppFooter />
  </v-app>
</template>

<script>
import AppToolBar from '@/components/core/AppToolBar'
import AppDrawer from '@/components/core/AppDrawer'
import AppFooter from '@/components/core/AppFooter'
import UserService from '@/services/user'


export default {
  components: { AppToolBar, AppDrawer, AppFooter },
  async mounted() {
    // Verified token
    try {
      await UserService.me()
    } catch (e) {
      console.log(e)
    }
  }
}
</script>
<style>
.theme--light.v-application {
  background: #f0f0f7;
}

.v-breadcrumbs__item,
.v-breadcrumbs__divider,
.v-breadcrumbs__item--disabled {
  color: #757575;
}
</style>
