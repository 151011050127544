/**
 * Send the menu sections
 * @author Edwin Verdú
 * @param {Array} menu - menu sections
 * 
 */

export function setMenu({ commit }, menu) {
  commit('SET_MENU', menu)
}
