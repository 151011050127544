export default [
  {
    path: '/parametros',
    meta: { title: 'Parámetros' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Setting',
        meta: { title: 'Parámetros', section: 'setting' },
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/settings/Edit')
      }
    ]
  },
  {
    path: '/log-actividades',
    meta: { title: 'Log de Actividades' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'ActivityLog',
        meta: { title: 'Log de Actividades', section: 'log-actividad' },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/activities/Index'
          )
      }
    ]
  }
]
