<template>
  <v-app-bar
    app
    class="header"
    :height="$vuetify.breakpoint.smAndDown ? 40 : 80"
  >
    <v-container
      class="d-flex flex-direction-row justify-space-between align-end container"
    >
      <!-- LOGO -->
      <picture>
        <source
          srcset="../../assets/AV_003_logo_header.svg"
          media="(min-width: 960px)"
        />
        <img
          src="../../assets/logo_mobile.svg"
          alt="AV Securities"
          :class="$vuetify.breakpoint.mdAndUp ? 'mb-3' : 'mb-2'"
          :width="$vuetify.breakpoint.mdAndUp && 220"
          :height="$vuetify.breakpoint.mdAndUp && 50"
        />
      </picture>
      <!-- MENU HAMBURGER -->
      <v-app-bar-nav-icon
        @click.stop="toggleDrawer"
        class="hidden-md-and-up"
        color="white"
      ></v-app-bar-nav-icon>

      <v-spacer class="hidden-sm-and-down" />

      <app-menu :items="items" @click="handleMenuClick">
        <template #after>
          <!-- Username -->
          <span role="button" class="menu-item">
            <v-icon class="mr-2" v-text="'mdi-account'" />{{ username }}
          </span>

          <!-- Logout -->
          <span role="button" class="menu-item" @click="handleLogout">
            <v-icon class="mr-2" v-text="'mdi-logout-variant'" />Salir
          </span>
        </template>
      </app-menu>
    </v-container>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Menu from '@/api/menu'
import AppMenu from './AppMenu'

export default {
  components: { AppMenu },
  name: 'AppToolBar',
  data() {
    return {
      notifications: [],
      objs: [
        {
          icon: 'mdi-account-box',
          href: '/perfil',
          title: 'Perfíl',
          click: () => {}
        },
        {
          icon: 'mdi-logout',
          title: 'Salir',
          click: async () => {
            try {
              await this.logout()
            } catch (error) {
              console.log(error)
            } finally {
              this.$router.push({ name: 'Login' })
            }
          }
        },
        {
          icon: 'mdi-logout',
          title: 'Usuarios',
          click: async () => {
            try {
              await this.logout()
            } catch (error) {
              console.log(error)
            } finally {
              this.$router.push({ name: 'Login' })
            }
          }
        }
      ],
      item: 1,
      items: Menu
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    username() {
      const firstName = this.user?.first_name.split(' ')[0]
      const lastName = this.user?.last_name.split(' ')[0]
      return firstName?.concat(' ').concat(lastName)
    },
    heightNavbar() {
      return this.$vuetify.breakpoint.smAndDown ? 40 : 120
    }
  },
  methods: {
    ...mapActions(['toggleDrawer']),
    ...mapActions('auth', ['logout']),

    async handleLogout() {
      try {
        await this.logout()
      } catch (error) {
        console.log(error)
      } finally {
        this.$router.push({ name: 'Login' })
      }
    },

    handleMenuClick(item) {
      this.$router.push({ name: item.component })
    }
  }
}
</script>
<style lang="sass" scoped>
header.header
  height: 40px

.theme--light.v-app-bar.v-toolbar.v-sheet
  background: #01579B

.container
  padding-left: 6px
  padding-right: 15px

@media only screen and (min-width: 960px)
  header.header
    height: 120px

  .theme--light.v-app-bar.v-toolbar.v-sheet
    background: white
    height: 120px
</style>
