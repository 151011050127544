import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './modules/auth'
import menu from './modules/menu'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'menu']
})

// export default new Vuex.Store({
const store = new Vuex.Store({
  state: {
    drawer: false,
    loading: false
  },
  mutations: {
    TOGGLE_DRAWER: state => (state.drawer = !state.drawer),
    SET_DRAWER: (state, value) => (state.drawer = value),
    SET_LOADING: (state, value) => (state.loading = value)
  },
  actions: {
    toggleDrawer({ commit }) {
      commit('TOGGLE_DRAWER')
    },
    setDrawer({ commit }, payload) {
      commit('SET_DRAWER', payload)
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload)
    }
  },
  modules: {
    auth,
    menu
  },
  plugins: [vuexLocal.plugin]
})

Vue.prototype.$store = store

export default store
