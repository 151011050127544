import axios from '@/plugins/axios'

/**
 * Send a post request to autenticate the user
 * @author Cauly Pérez
 * @param {Object} user - User data form
 * @param {Object} remember - Remember data form
 * 
 */
export async function signIn({ commit }, user) {
  commit('SET_LOADING', true, { root: true })
  try {
    const { data } = await axios.post('/api/v1/login', {
      email: user.email,
      password: user.password,
      remember: user.remember
    })

    commit('SET_USER', data.user)
    commit('SET_TOKEN', data.token)
  } catch (e) {
    throw e.response.data.errors.email[0]
  } finally {
    commit('SET_LOADING', false, { root: true })
  }
}

export function setCurrentUser({ commit }, user) {
  commit('SET_USER', user)
}

export async function me({ commit }) {
  try {
    commit('SET_LOADING', true, { root: true })
    const { data } = await axios.get('/api/user')
    commit('SET_USER', data)
  } catch (e) {
    throw 'Usuario Inválido'
  } finally {
    commit('SET_LOADING', false, { root: true })
  }
}

export async function logout({ commit }) {
  try {
    commit('SET_LOADING', true, { root: true })

    await axios.post('/api/v1/logout')

    commit('SET_TOKEN', null)
    commit('SET_USER', null)
  } catch (e) {
    throw 'Error desconocido'
  } finally {
    commit('SET_LOADING', false, { root: true })
  }
}