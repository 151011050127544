import Vue from 'vue'
import Router from 'vue-router'
import routes from './path'
import store from '../store'
import { hasPermission } from '../utils'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

/**
 * Protegemos las rutas para que solo puedan acceder
 * si el usuario esta autentificado, de lo contrario
 * lo redirigimos a la ventana de Login
 */

router.beforeEach((to, from, next) => {
  const user = store.state.auth.user
  const auth = to.matched.some(m => m.meta.auth)
  const permission = to.meta?.permission

  // Authentication
  if (auth && !user) {
    return next({ name: 'Login' })
  }

  if (auth && user && permission && !hasPermission(user, permission)) {
    next({ name: 'Dashboard' })
  } else next()
})

export default router
