import DefaultLayout from '@/layouts/DefaultLayout'
import Dashboard from '@/views/Dashboard'
import general from './general'
import settings from './settings'
import administrator from './administrator'

export default [
  // Login
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/Login')
  },
  {
    path: '/recuperar-contrasena',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword'
      )
  },
  {
    path: '/restablecer-contrasena',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '../views/auth/ResetPassword'
      )
  },
  {
    path: '/recuperar-usuario',
    name: 'ForgotUser',
    component: () =>
      import(
        /* webpackChunkName: "forgot-username" */ '../views/auth/ForgotUsername'
      )
  },
  {
    path: '/configurar-usuario',
    name: 'ConfigPassword',
    component: () =>
      import(
        /* webpackChunkName: "config-password" */
        '../views/auth/ConfigPassword'
      )
  },
  // Dashboard
  {
    path: '*',
    redirect: { name: 'Login' }
  },
  // Dashboard
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    meta: { auth: true, title: 'Dashboard' },
    children: [
      {
        path: '/dashboard',
        component: Dashboard
      },
      // General
      {
        path: '/general',
        component: { template: '<router-view />' },
        children: general
      },
      {
        path: '/administracion',
        component: { template: '<router-view />' },
        children: administrator
      },
      // Settings
      {
        path: '/parametros',
        component: { template: '<router-view />' },
        children: settings
      }
    ]
  }
]
