import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  length,
  min,
  min_value,
  max,
  max_value,
  size,
  confirmed,
  ext,
  numeric,
  alpha,
  alpha_num,
  alpha_dash,
  alpha_spaces,
  digits,
  regex,
} from 'vee-validate/dist/rules'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'


const validateImage = (file, width, height) => {
  const URL = window.URL || window.webkitURL;
  return new Promise(function (resolve) {
      var image = new Image();
      image.onerror = function () { return resolve(false); };
      image.onload = function () { return resolve(image.width <= width && image.height <= height); };
      image.src = URL.createObjectURL(file);
  });
};

const maxDimensions = async (file, [width, height]) => {
  const response = await  validateImage(file, width, height)
  const message = `El campo {_field_} debe ser menor o igual a ${width}px por ${height}px`
  return response ? response : message
}

// Add rules
extend('required', required)
extend('email', email)
extend('length', length)
extend('min_value', min_value)
extend('min', min)
extend('max_value', max_value)
extend('max', max)
extend('ext', ext)
extend('size', size)
extend('numeric', numeric)
extend('confirmed', confirmed)
extend('alpha', alpha)
extend('alpha_num', alpha_num)
extend('alpha_spaces', alpha_spaces)
extend('digits', digits)
extend('regex', regex)
extend('alpha_dash', alpha_dash)
extend('max_dimensions', maxDimensions)

localize('es', es)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
