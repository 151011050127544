<template>
  <div class="menus hidden-sm-and-down">
    <slot name="before" />
    <template v-for="(item, i) in items">
      <!-- Menu -->
      <span
        v-if="!item.items && hasPermission(item.permission)"
        :key="i"
        role="button"
        class="menu-item"
        @click="$emit('click', item)"
      >
        {{ item.title }}
      </span>

      <!-- SubMenu -->
      <sub-menu
        v-else
        v-on="$listeners"
        v-bind="$attrs"
        :permission="item.permission"
        :key="i"
        :name="item.title"
        :items="item.items"
      />
    </template>
    <slot name="after" />
  </div>
</template>
<script>
import SubMenu from '../commons/SubMenu'
import { hasPermission } from '@/utils'
import { mapState } from 'vuex'
export default {
  name: 'AppMenu',
  components: { SubMenu },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    emitClickEvent(item) {
      this.$emit('click', item)
      this.openMenu = false
    },
    hasPermission(permission) {
      return hasPermission(this.user, permission)
    }
  },
  data: () => ({
    openMenu: false
  })
}
</script>
<style lang="sass">
.menu-content
  border-top: 4px solid #00559e
  border-top-left-radius: 0
  border-top-right-radius: 0
  &.submenu
    border-top: none


.menus
  display: flex
  flex-direction: row
  justify-content: space-between
  align-content: flex-end
  align-items: flex-end


.menu-item
  color: #686f8d
  text-decoration: none
  margin-left: 25px
  margin-right: 25px
  min-height: 50px
  min-width: 40px
  margin-bottom: 0px
  padding-top: 44px
  padding-bottom: 12px
  &:hover
    color: #00559e
  &:last-of-type
    margin-right: 0px
</style>
