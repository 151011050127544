import axios from '@/plugins/axios'

export default class BaseService {
  static resource() {
    return ''
  }

  static baseURL() {
    return process.env.VUE_APP_API_URL
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetch(
    filters = {},
    sortBy = null,
    sortDesc = false,
    page = 1,
    limit = 999
  ) {
    // Eliminamos los parametros nullos o en blanco. Ejm: `filter[query]=`
    Object.keys(filters).forEach(key => {
      if (!filters[key]) delete filters[key]
    })

    let params = {
      page: page,
      limit: limit < 0 ? 0 : limit,
      ...filters
    }

    // Sort column
    if (sortBy) params.sort = sortDesc ? `-${sortBy}` : sortBy

    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}`, {
      params
    })
    // return data.data
    return data
  }

  /**
   * Get a resource by id
   * @param {Number} id - resource Id
   * @param {Array} params - query params
   * @returns {object} resource
   */
  static async find(id, params = {}) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/${id}`,
      {
        params: params
      }
    )
    return data.data
  }

  /**
   * Create a new resource
   * @param {object} payload  - Data to save
   * @returns {object} resource
   */
  static async post(payload = {}) {
    const { data } = await axios.post(
      `${this.baseURL()}/${this.resource()}`,
      payload
    )
    return data.data
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @returns {object} resource
   */
  static async update(id, payload = {}) {
    const { data } = await axios.put(
      `${this.baseURL()}/${this.resource()}/${id}`,
      payload
    )
    return data.data
  }

  /**
   * Delete a existent resource
   * @param {Number} id - resource Id
   * @returns {object} resource
   */
  static async delete(id) {
    const { data } = await axios.delete(
      `${this.baseURL()}/${this.resource()}/${id}`
    )
    return data
  }

  /**
   * Create or Modify a existent resource
   * @param {object} payload  - Data to create or update
   * @returns {object} resource
   */
  static async save(payload) {
    if (payload.id) return this.update(payload.id, payload)
    return this.post(payload)
  }

  /**
   * Delete multiples resources by Id
   * @param {Array} items - Resources with Id to delete
   * @returns {boolean}
   */

  static async batchDelete(items) {
    try {
      await Promise.all(
        items.map(async item => {
          await this.delete(item.id)
        })
      )
      return true
    } catch {
      throw 'No se pudieron eliminar todos los registros'
    }
  }
}
