<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :open-on-hover="openOnHover"
    :transition="transition"
    :value="openMenu"
    :bottom="bottom"
    :auto="auto"
    :content-class="subMenu ? 'menu-content submenu' : 'menu-content'"
  >
    <template v-slot:activator="{ on }">
      <v-list-item
        v-if="subMenu"
        class="d-flex justify-space-between"
        v-on="on"
      >
        <v-list-item-title>{{ name }}</v-list-item-title>
        <div class="flex-grow-1"></div>
        <v-icon class="list_color">mdi-chevron-right</v-icon>
      </v-list-item>
      <span
        v-else-if="hasPermission(permission) && hasValidChildrens()"
        role="button"
        class="menu-item"
        v-on="on"
        :color="color"
        @click="openMenu = true"
      >
        {{ name }}</span
      >
    </template>
    <v-list dense nav>
      <template v-for="(item, index) in items">
        <v-divider v-if="item.isDivider" :key="index" />
        <sub-menu
          v-else-if="item.items && hasPermission(item.permission)"
          :key="index"
          :name="item.title"
          :items="item.items"
          :open-on-hover="false"
          :offset-x="true"
          :offset-y="false"
          :sub-menu="true"
          :bottom="false"
          :auto="false"
          @click="emitClickEvent"
        />
        <v-list-item
          v-else-if="hasPermission(item.permission)"
          :key="index"
          @click="emitClickEvent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import { hasPermission } from '@/utils'
import { mapState } from 'vuex'
export default {
  name: 'SubMenu',
  props: {
    name: {
      type: String,
      required: true
    },
    permission: String,
    icon: String,
    items: Array,
    color: {
      type: String,
      default: 'primary'
    },
    offsetX: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: Boolean,
      default: true
    },
    openOnHover: {
      type: Boolean,
      default: false
    },
    subMenu: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: 'v-menu-transition'
    },
    bottom: {
      type: Boolean,
      default: true
    },
    auto: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('menu', ['menu']),
    section() {
      return this.menu?.find(item => item.value === this.permission)
    },
    sectionChildrens() {
      const childrens = this.section?.children.map(item => {
        return item.value
      })
      return childrens
    }
  },
  methods: {
    emitClickEvent(item) {
      this.$emit('click', item)
      this.openMenu = false
    },
    hasPermission(permission) {
      return hasPermission(this.user, permission)
    },
    hasValidChildrens() {
      return this.sectionChildrens?.some(item => this.hasPermission(item) & item !== 'menu-users')
    }
  },
  data: () => ({
    openMenu: false
  })
}
</script>
<style scoped>
.list_color {
  color: #686f8d !important;
}
</style>
