export default [
  {
    path: 'mantenimiento',
    name: 'Maintenance',
    meta: {
      title: 'Mantenimiento',
      section: 'maintenance'
    },
    component: () =>
      import(
        /* webpackChunkName: "Maintenance" */ '@/views/admin/maintenance/Edit'
      )
  },
  {
    path: 'parametros',
    meta: { title: 'Administración' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'adminParameters',
        meta: { title: 'Parámetros de Configuración', section: 'setting' },
        component: () =>
          import(
            /* webpackChunkName: "adminParameters" */ '@/views/admin/parameters/Index'
          )
      },
      {
        path: 'creacion-periodo-visualizacion',
        name: 'DisplayPeriodCreate',
        meta: {
          title: 'Creación Periodo de Visualización',
          section: 'setting'
        },
        component: () =>
          import(
            /* webpackChunkName: "DisplayPeriodCreate" */ '@/views/admin/parameters/partials/DisplayPeriodEdit'
          )
      },
      {
        path: 'edicion-periodo-visualizacion',
        name: 'DisplayPeriodEdit',
        meta: {
          title: 'Edición Periodo de Visualización',
          section: 'parametro'
        },
        component: () =>
          import(
            /* webpackChunkName: "DisplayPeriodEdit" */ '@/views/admin/parameters/partials/DisplayPeriodEdit'
          )
      }
    ]
  }
]
