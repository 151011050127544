export default [
  {
    title: 'Usuarios',
    permission: 'menu-users',
    name: 'Users',
    component: 'Users',
    icon: 'mdi-account-supervisor'
  },
  {
    title: 'Estado de Cuenta',
    permission: 'menu-account-statement',
    name: 'AccountStatement',
    icon: 'mdi-file-chart',
    component: 'AccountStatement'
    // items: [
    //   {
    //     name: 'Generate',
    //     title: 'Generar',
    //     permission: 'create-account-statement',
    //     component: 'AccountStatementGenerate'
    //   },
    //   {
    //     name: 'Publish',
    //     title: 'Publicar',
    //     permission: 'publish-account-statement',
    //     component: 'AccountStatementPublish'
    //   },
    //   {
    //     name: 'Replace',
    //     title: 'Sustituir',
    //     permission: 'replace-account-statement',
    //     component: 'AccountStatementReplace'
    //   },
    // ]
  },
  {
    title: 'Brokers',
    permission: 'menu-brokers',
    name: 'Brokers',
    component: 'Brokers',
    icon: 'mdi-handshake'
  },
  {
    title: 'Administración',
    permission: 'menu-administration',
    name: 'Administration',
    icon: 'mdi-account-cog',
    items: [
      {
        name: 'Groups',
        title: 'Roles y Permisos',
        permission: 'menu-roles',
        component: 'Groups',
        items: [
          {
            name: 'GroupAdmin',
            title: 'Administradores',
            component: 'GroupsAdmin',
            permission: 'menu-roles'
          },
          {
            name: 'GroupsCustomer',
            title: 'Clientes',
            component: 'GroupsCustomer',
            permission: 'menu-roles'
          }
        ]
      },
      {
        name: 'Languages',
        title: 'Multi-idiomas',
        permission: 'menu-multi-languages',
        component: 'Languages'
      },
      {
        name: 'Questions',
        title: 'Preguntas de Seguridad',
        permission: 'menu-questions',
        component: 'Questions'
      },
      {
        name: 'ActivityLog',
        title: 'Log de Actividades',
        permission: 'menu-activities-log',
        component: 'ActivityLog'
      },
      {
        name: 'Settings',
        title: 'Parámetros',
        permission: 'menu-settings',
        component: 'adminParameters'
      },
      {
        name: 'Maintenance',
        title: 'Mantenimiento',
        permission: 'menu-maintenance',
        component: 'Maintenance'
      },
    ]
  },
]
