export function debounce(func, wait = 400) {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

export function hasPermission(user, permission) {
  return user.roles.some(role => {
    return role.permissions.some(e => e === permission)
  })
}
