<template>
  <v-navigation-drawer
    :value="drawer"
    @input="setDrawer($event)"
    :dark="dark"
    :width="260"
    right
    app
    class="d-print-none hidden-md-and-up"
    fixed
    temporary
    overlay-color="#00559E"
  >
    <v-container fluid class="d-flex justify-end">
      <v-icon color="#00559E" @click="toggleDrawer" class="">mdi-close</v-icon>
      <!-- <img src="../../assets/close.png" alt="Close" class="mb-2" @click="toggleDrawer" /> -->
    </v-container>
    <v-container fluid>
      <img class="margin-logo" height="40" :src="logo" alt="AV - AGIL" />
    </v-container>

    <v-list :dark="dark" class="margin-list">
      <template v-for="item in items">
        <!--group with subitems-->
        <v-list-group
          v-if="item.items"
          v-show="hasPermission(item.permission)"
          :key="item.title"
          :group="item.group"
          :active-class="dark ? 'list_color' : 'list_color'"
          no-action
        >
          <template #activator>
            <v-list-item-title class="font-weight-bold item-list">
              <v-icon>
                {{ item.icon }}
              </v-icon>
              <span class="ml-2"> {{ item.title }} </span>
            </v-list-item-title>
          </template>

          <template v-for="subItem in item.items">
            <!--sub group-->
            <v-list-group
              v-if="subItem.items"
              v-show="hasPermission(subItem.permission)"
              :key="subItem.name"
              :group="subItem.group"
              sub-group
              class="item-list"
            >
              <template #activator>
                <v-list-item-title class="font-weight-bold">{{
                  subItem.title
                }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="grand in subItem.items"
                :key="grand.name"
                :to="genChildTarget(item, grand)"
                :href="grand.href"
                v-show="hasPermission(grand.permission)"
                ripple
              >
                <v-list-item-content>
                  <v-list-item-title>{{ grand.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!--child item-->
            <v-list-item
              v-else
              v-show="hasPermission(subItem.permission)"
              :key="subItem.name"
              :to="genChildTarget(item, subItem)"
              :href="subItem.href"
              :disabled="subItem.disabled"
              :target="subItem.target"
              :active-class="dark ? 'list-color' : 'black--text'"
              ripple="ripple"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <!-- <span class="body-2 grey--text text--lighten-1">{{ subItem.title }}</span> -->
                  <span class="list_color text--darken-2">{{
                    subItem.title
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="subItem.action">
                <v-icon :class="[subItem.actionClass || 'list_color']">{{
                  subItem.action
                }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-group>
        <v-subheader v-else-if="item.header" :key="item.name">{{
          item.header
        }}</v-subheader>
        <v-divider v-else-if="item.divider" :key="item.name"></v-divider>
        <!--top-level link-->
        <v-list-item
          v-else
          v-show="hasPermission(item.permission)"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          ripple
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="item.name"
        >
          <v-list-item-action v-if="item.icon" class="d-flex flex-row">
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title class="init ml-2">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-action>
          <v-list-item-content> </v-list-item-content>
          <v-list-item-action v-if="item.subAction">
            <v-icon class="success--text">{{ item.subAction }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-list class="margin-list">
      <v-list-item class="bottom_list">
        <v-btn text color="#686F8D" class="navbutton item-list" small>
          <span><v-icon>mdi-account</v-icon></span>
          <span class="ml-3">{{ username }}</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="bottom_list item-list exit">
        <v-btn
          text
          @click="objs[1].click"
          color="#686F8D"
          class="navbutton"
          small
        >
          <span><v-icon>mdi-logout-variant</v-icon></span>
          <span class="ml-3">Salir</span>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Menu from '@/api/menu'
import { hasPermission } from '@/utils'

export default {
  name: 'AppDrawer',
  data() {
    return {
      activate: 'primary--text activate-list-item',
      item: 1,
      items: Menu,
      logo: require('@/assets/AV_003_logo_header.svg'),
      dark: false,
      // drawer: false
      objs: [
        {
          icon: 'mdi-account-box',
          href: '/perfil',
          title: 'Perfíl',
          click: () => {}
        },
        {
          icon: 'mdi-logout',
          title: 'Salir',
          click: async () => {
            try {
              await this.logout()
            } catch (error) {
              console.log(error)
            } finally {
              this.$router.push({ name: 'Login' })
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapState(['drawer']),
    ...mapState('auth', ['user']),
    username() {
      const firstName = this.user?.first_name.split(' ')[0]
      const lastName = this.user?.last_name.split(' ')[0]
      return firstName?.concat(' ').concat(lastName)
    }
  },
  methods: {
    ...mapActions(['setDrawer']),
    ...mapActions('auth', ['logout']),
    ...mapActions(['toggleDrawer']),
    genChildTarget(item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component
        }
      }
      return { name: `${item.group}/${subItem.name}` }
    },
    hasPermission(permission) {
      return hasPermission(this.user, permission)
    }
  }
}
</script>
<style scoped>
/* .v-list-group.v-list-group--no-action {
  border-bottom: 1px solid #b0b0b0;
} */

.v-list-item__title {
  color: #686f8d;
}

.margin-logo {
  margin-left: 35px;
  padding: 0;
}

.margin-list {
  padding: 0;
  /* padding-left: 32px; */
}

.margin-list a {
  padding-left: 47px;
  font-weight: bold;
}

.item-list {
  /* padding: 0; */
  padding-left: 32px !important;
}

.navbutton {
  text-transform: none !important;
  font-weight: bold;
}

.bottom_list {
  /* padding-left: 32px !important; */
  margin-left: -3.5px;
}
.bottom_list:last-child {
  /* padding-left: 32px !important; */
  margin-left: 1.5px;
}

.list_color {
  color: #686f8d !important;
  font-weight: bold;
}

.theme--light.v-icon {
  color: #686f8d !important;
}
.init {
  margin-left: 2px;
}
</style>
