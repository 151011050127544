<template>
  <v-app class="font mt-0 pt-0">
    <v-main>
      <v-container fluid class="content-box mt-0 pt-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: 'App',
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
.theme--light.v-application {
  background: #fbfbfb;
  font-family: 'Lato', sans-serif;
}

.v-tabs-bar {
  border-bottom: 1px solid #e0e0e0;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  /* background-color: #555b00 !important; */
  /* color: #e8e6e3 !important; */
  color: #e8e6e3;
}

::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}
::-webkit-scrollbar {
  background-color: white;
  color: #aba499;
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #d8d8d8;
}
::-webkit-scrollbar-thumb:active {
  background-color: #d8d8d8;
}
::-webkit-scrollbar-corner {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* ::selection {
  background-color: #004daa !important;
  color: #e8e6e3 !important;
}
::-moz-selection {
  background-color: #004daa !important;
  color: #e8e6e3 !important;
} */

body {
  scrollbar-color: #01579b white;
}

html {
  scrollbar-color: #01579b white;
}

.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  border-radius: 10px;
}

.menu-content {
  max-height: 100% !important;
}
</style>
